import { graphql, useStaticQuery } from "gatsby"
import React from "react"
import styled from "styled-components"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import Layout from "../components/layout"
import SEO from "../components/seo"
import leak from "../images/leak.jpg"

const Container = styled.div`
  margin-bottom: 8rem;
  .intro,
  blockquote {
    color: #8c98a8;
    padding: 0 1rem;
  }
  blockquote {
    margin: 2rem auto;
  }
  .header {
    max-height: 60vh;
  }
  .content {
    margin: 2rem 8rem;
    .img {
      width: 100%;
      margin: 2rem auto;
      display: flex;
      justify-content: center;
      p {
        width: 50%;
        margin-right: 0;
        margin-left: 0;
      }
      img {
        margin: auto;
        height: auto;
        width: 50%;
        max-width: 60%;
        flex-grow: 2;
        box-shadow: 0 30px 60px -12px rgba(50, 50, 93, 0.25),
          0 18px 36px -18px rgba(0, 0, 0, 0.3),
          0 -12px 36px -8px rgba(0, 0, 0, 0.025);
      }
    }
    h1,
    h2,
    h3 {
      text-align: center;
      margin: 5rem auto 1rem;
    }
    h2 {
      border-top: 2px solid black;
      padding: 0.4rem;
      width: 67%;
    }
    p {
      line-height: 1.2;
      margin: auto 6rem;
    }
  }
  @media (max-width: 799px) {
    h1 {
      font-size: 1.2rem;
    }
    h2,
    h3 {
      width: 80% !important;
    }
    .content {
      margin: 2rem 0;
    }
    .intro {
      margin: 0 !important;
    }
    .img {
      flex-direction: column;
    }
    p {
      width: auto !important;
      margin: 1rem 2rem !important;
      font-size: 0.9rem;
      line-height: 1.4 !important;
    }
  }
`

const BathroomRemodel = () => {
  const data = useStaticQuery(
    graphql`
      query BathroomRemodel {
        header: file(relativePath: { eq: "bathroom.jpg" }) {
          childImageSharp {
            gatsbyImageData
          }
        }
        leak: file(relativePath: { eq: "leak.jpg" }) {
          childImageSharp {
            gatsbyImageData
          }
        }
        tile: file(relativePath: { eq: "tile.jpg" }) {
          childImageSharp {
            gatsbyImageData
          }
        }
      }
    `
  )

  console.log("data 2", getImage(data.header), data.header)
  return (
    <Layout>
      <SEO
        title="5 Things to keep in mind when you remodel your bathroom"
        description="Bathroom remodeling is a big investment and you want everything to line up well. Keeping certain things in mind can really help."
      />
      <Container>
        <GatsbyImage className="header" image={getImage(data.header)} />
        <div className="content">
          <h1>5 Things to keep in mind when you remodel your bathroom</h1>
          <p className="intro">
            Your bathroom is one of the most essential rooms in the house. Even
            if you don't think about it, it's a space you use a lot. When your
            bathroom needs work and you decide bathroom remodeling is a good
            idea, it's an investment into your home. Whatever you spend on the
            home repair services in that space, you are going to see again when
            you sell the home in the future. Plus, in the meantime, you get to
            enjoy the remodeled bathroom yourself. Here are just a few things
            you should keep in mind when you remodel your bathroom.
          </p>
          <h2>Moving Plumbing Is Costly</h2>
          <div className="img">
            <GatsbyImage image={getImage(data.leak)} />
            <p>
              When you work on bathroom remodeling, one of the first things you
              should think about is whether or not your bathroom is set up in a
              functional manner. If things are organized nicely and you just
              need to update the flooring, countertop, and so on, you can
              proceed. But if you want to move the bathtub, create a separate
              small space for the toilet and other such things, you will have to
              move the plumbing around. That is something that should only be
              done by a plumber and it can cost quite a bit. You will want to
              make sure you allow for that cost in the budget or you might start
              off spending everything you have on that part of the project.
            </p>
          </div>
          <h2>Consider Lighting</h2>
          <p>
            Light is key to any bathroom remodeling project. You need to be able
            to see well in that space as you get ready each morning. While not
            every bathroom has a window, or space for one, it might be something
            you want to add if you do have the room. You could also consider a
            skylight to give you the light you want without taking up any wall
            space. Skylights also allow you to keep privacy intact. If there's
            no way to get natural lighting, the lights above the mirror and in
            other locations are even more important. They need to work well in
            order to give you the look you want.
          </p>
          <h2>Coordination Is Key</h2>
          <div className="img">
            <p>
              You may have a certain countertop material, color, or style in
              mind. And you might know what tiles you like for the flooring. But
              you will need to coordinate every piece of the project together in
              order to get the end results you want. Choosing tile without
              thinking about the wall color or countertop materials without
              thinking of flooring could make the bathroom look like a jumble of
              materials slapped together and that's not a good result. Home
              repair services might include advice from an interior designer who
              can help guide you through different color schemes to keep the
              overall look cohesive.
            </p>
            <GatsbyImage image={getImage(data.tile)} />
          </div>
          <h2>Remember Ventilation</h2>
          <p>
            The bathroom is generally a smaller room and there are a lot of warm
            showers that can really heat up that space. You need to be able to
            ventilate the room and that should be something to remember during
            bathroom remodeling. Again, you could install a window if you don't
            already have one to get fresh air in and out. Otherwise, you need to
            make sure you have the proper gans to get the air moving so things
            don't get too humid in the room.
          </p>
          <h2>On Adding Luxury</h2>
          <p>
            While the bathroom is a functional space, when you are enlisting
            help from home repair services in bathroom remodeling, you may want
            to add some extras to make the space feel like somewhere you could
            relax and enjoy. Heated tiles on the floors can be a big selling
            point in the future. They're also something you can really enjoy on
            your own feet in the meantime. Even small touches of luxury can take
            the remodeling job to the next level.
          </p>
          <p>
            <br />
            When you are starting to consider bathroom remodeling, our
            professionals are here to help. We can help you with the design all
            the way through implementation and beyond. We want to make your
            bathroom dream a reality. Visit our website for more information on
            our services. And if you are looking for a home improvement
            contractor or a handyman in the Akron OH area don't heistate to{" "}
            <a href="/">contact us</a>
          </p>
        </div>
      </Container>
    </Layout>
  )
}

export default BathroomRemodel
